
	import { Component, Vue } from "vue-property-decorator";
	import request from "../../request";
	import moment from "moment";
	import qs from "qs";
	import {
		AutoComplete,
		Button,
		Cascader,
		DatePicker,
		Form,
		FormItem,
		Modal,
		Option,
		Select,
		Upload
	} from "view-design";
	import { Route } from "vue-router";

	interface Tree {
		label:string,
		value:number,
		type:"system" | "category" | "device",
		loading?:boolean,
		children?:Tree[]
	}

	interface resolve {
		what:string,
		how:string,
		why:string
	}

	@Component({
		components: {
			Form,
			FormItem,
			Select,
			Option,
			DatePicker,
			AutoComplete,
			Upload,
			Button,
			Cascader,
			Modal
		}
	})
	export default class AddRequest extends Vue {
		floorTree:Tree[] = [];
		deviceTree:Tree[] = [];
		resolve:string[] = [];
		info = {
			space: [],
			device: [],
			deadline: new Date(Date.now() + 7 * 24 * 3600 * 1000),
			priority: 2,
			describe: "",
			fileList: []
		};
		loading:boolean = false;
		imageUrl:string = "";
		visible:boolean = false;
		readonly option = {
			disabledDate (date:Date):boolean {
				return date && date.valueOf() < Date.now();
			}
		};
		private resolveSource:resolve[] = [];
		private readonly listener = this.$store.subscribe(mutation => {
			if (mutation.type === "findRoom" && mutation.payload.path) {
				this.info.space = mutation.payload.path;
			}
		});

		loadDevice (item:Tree, callback:Function) {
			item.loading = true;
			let url:string;
			let params;
			if (item.type === "system") {
				url = "/device/devicecategory/";
				params = {
					DeviceType__mepsystemtype: item.value,
					DeviceType__Device__room__floor: this.$store.state.floorValue,
					DeviceType__Device__room: this.info.space[ 2 ]
				};
			} else {
				url = "/device/device2/";
				params = {
					devicetype__device_category: item.value,
					room: this.info.space[ 2 ],
					room__floor: this.$store.state.floorValue
				};
			}
			request({
				url,
				params
			}).then(({ data }) => {
				if (url === "/device/devicecategory/") {
					item.children = data.map((t:any):Tree => ({
						label: t.name,
						value: t.id,
						loading: false,
						type: "category",
						children: []
					}));
				} else {
					item.children = data.results.map((t:any):Tree => ({
						label: t.device_category_name + t.code,
						value: t.id,
						type: "device"
					}));
				}
				item.loading = false;
				callback();
			});
		}

		getCurrentDeviceTree () {
			request({
				url: "/device/mepsystemtype/",
				params: {
					DeviceType__Device__room: this.info.space[ 2 ]
				}
			}).then(({ data }) => {
				this.deviceTree = data.map((t:any) => ({
					label: t.name,
					value: t.id,
					type: "system",
					loading: false,
					children: []
				}));
			});
		}

		searchDescribe (text:string) {
			if (text === "") {
				this.resolve = [];
			} else {
				let regexp:RegExp = new RegExp(text);
				let set:Set<string> = new Set();
				this.resolveSource.forEach(t => {
					if (regexp.test(t.what)) {
						set.add(t.what);
					}
				});
				if (set.size === 0) {
					set.add("没有相关建议");
				}
				this.resolve = Array.from(set);
			}
		}

		focusToRoom (value:number[]) {
			if (value && value.length === 3) {
				request(`/space/room/${ value[ 2 ] }/`).then(({ data }) => {
					this.$store.commit("findRoom", { id: data.room_guid, floor: value[ 1 ] });
				});
			}
			this.getCurrentDeviceTree();
		}

		previewFile (file:any) {
			let url = file.response.data.fileurl;
			if (!file.response.data.image) {
				window.open(url);
			} else {
				this.imageUrl = url;
				this.visible = true;
			}
		}

		handleExceeded () {
			this.$Notice.error({ desc: "文件大小超出限制" });
		}

		submit () {
			this.loading = true;
			const tempData = this.info;
			const data = {
				opt: "create",
				describe: tempData.describe,
				mepsystem: tempData.device[ 1 ],
				priority: tempData.priority,
				docids: (this.$refs.upload as any).fileList.map((t:any) => t.response.data.docId),
				device: tempData.device[ 2 ],
				space: tempData.space,
				deadline: tempData.deadline ? moment(tempData.deadline).format("YYYY-MM-DD") : null
			};
			request({
				url: "/repair/eventopt/",
				method: "post",
				transformRequest: [ function (data) {
					return qs.stringify(data, { arrayFormat: "brackets" });
				} ],
				data
			}).then(() => {
				this.$router.go(-1);
			});
		}

		mounted () {
			request("/prj/facilityfloorrooms/").then(({ data }) => {
				this.floorTree = [ data.data[ 0 ] ];
			});
			request("/repair/rest/requirement/").then(({ data }) => {
				this.resolveSource = data;
			});
			this.getCurrentDeviceTree();
		}

		beforeRouteLeave (to:Route, from:Route, next:Function) {
			this.listener();
			next();
		}
	}
